import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvService } from '@app/common/services/env.service';
import { constants } from '@app/common/constants/app.constants';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupplierRegistrationService {

  private url: string;

  constructor(private http: HttpClient,
    private env: EnvService) {
    this.url = this.env.apiGatewayFront;
  }

  getEconomicActivities(): Observable<any> {
    return this.http.get(`${this.url}${constants.config.supplierGetEconomicActivities}`);
  }

  getEconomicSubActivities(idEconomicActivity): Observable<any> {
    return this.http.get(`${this.url}${constants.config.supplierGetEconomicSubActivities}?id=${idEconomicActivity}`);
  }

  getTaxRegimes(): Observable<any> {
    return this.http.get(`${this.url}${constants.config.supplierGetTaxRegimes}`);
  }

  getCities(textSearch): Observable<any> {
    return this.http.get(`${this.url}${constants.config.supplierGetCities}?textSearch=${textSearch}`);
  }

  validateContactIdentificationRegisterSupplier(idDocument, document): Observable<any> {
    return this.http.get(`${this.url}${constants.config.supplierValidateContactIdentification}?idDocument=${idDocument}&document=${document}`);
  }

  validateNitSupplierRegister(documentId, documentIdVerify): Observable<any> {
    return this.http.get(`${this.url}${constants.config.supplierValidateNitSupplierRegister}?documentId=${documentId}&documentIdVerify=${documentIdVerify}`);
  }

  validateEmailContactSupplier(emailContact): Observable<any> {
    return this.http.get(`${this.url}${constants.config.supplierValidateEmailContactSupplier}?emailContact=${emailContact}`);
  }

  getIdentificationsTypes(): Observable<any> {
    return this.http.get(`${this.url}${constants.config.getTypeDocumentsUser}`);
  }

  postRegisterSupplier(supplierModel, listFiles): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('supplier', JSON.stringify(supplierModel));
    formData.append('company', JSON.stringify(environment['company']));


    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');

    for (let i = 0; i < listFiles.length; i++) {
      const file = listFiles[i];
      if (file.file) {
        formData.append(file.key, file.file, file.file.name);
      }
    }

    return this.http.post(`${this.url}${constants.config.supplierRegister}`, formData,
      { headers });
  }

  postRegisterContactSupplier(supplierContactmodel): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('supplier', JSON.stringify(supplierContactmodel));
    formData.append('company', JSON.stringify(environment['company']));

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    return this.http.post(`${this.url}${constants.config.supplierRegister}`, formData,
      { headers });
  }

  postRegisterContactSupplierProblems(supplierContactmodel): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('supplier', JSON.stringify(supplierContactmodel));
    formData.append('company', JSON.stringify(environment['company']));

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    return this.http.post(`${this.url}${constants.config.supplierRegisterProblems}`, formData,
      { headers });
  }
}
