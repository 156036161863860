import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '@app/common/services/env.service';
import { constants } from '@app/common/constants/app.constants';
import { InfoSupplierModel } from "@app/common/types/InfoSupplierModel";
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private contenidoCms: any = {};
  private contentHeader: any = {};

  constructor(private http: HttpClient,
    private env: EnvService) {
  }

  contentCms(ubucation: string) {
    const urlCms = this.env.apiGatewayFront + constants.config.getContentCms + `=${ubucation}`;
    this.contenidoCms = this.http.get(urlCms);
    return this.contenidoCms;
  }

  getDataLogin(data: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const body: FormData = new FormData();
    const url = this.env.apiGatewayFront + constants.config.getContent_loginAGP;
    body.append('formData', JSON.stringify(data));
    body.append('company', JSON.stringify(environment['company']));
    return this.http.post<any>(url, body, { headers });
  }

  getUserInfo(userId) {
    const url = this.env.apiGatewayFront + constants.config.getUserInfo;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const body: FormData = new FormData();

    body.append('userId', userId);

    return this.http.post(url, body, { headers });
  }

  getInfoSupplier() {

    const url = this.env.apiGatewayFront + constants.config.getProveedores_list;
    return this.http.get<InfoSupplierModel>(url);
  }

  // getAdverstismentsLogin(): Observable<any> {
  //   console.log("ENTRE 3");
  //   const url = this.env.apiGatewayFront + `${constants.config.agp_app_login}`;

  //   return this.http.get<any>(url).pipe(
  //     retry(1)
  //   );
  // }
}
