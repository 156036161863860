import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EnvService {


  // The values that are defined here are the default values that can
  // be overridden by env.js

  // API url
  public apiGatewayFront: any = environment['apiGatewayFront'];
  public apiGatewayBackOffice: any = environment['apiGatewayBackOffice'];
  public urlPath: any = environment['urlPath'];
  public clientName: any = environment['clientName'];
  public customCss: any = environment['customCss'];
  public siteKeyCaptcha: any = environment['siteKeyCaptcha'];
  public siteCompany: any = environment['company'];


  // Whether or not to enable debug mode
  public enableDebug = true;

  constructor() {

  }
}
