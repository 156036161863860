import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { EnvService } from '@app/common/services/env.service';
import { constants } from '@app/common/constants/app.constants';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private contenidoCms: any = {};
  private contactos_par: any = {};
  constructor(private http: HttpClient,
    private env: EnvService) {
  }

  getService(form): Observable<any> {
    const body = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    for (const propiedad in form.value) {
      let valuePropety = form.value[propiedad];
      if (valuePropety == null) {
        valuePropety = '';
      }
      body.append(propiedad, form.value[propiedad]);
    }
    const url = this.env.apiGatewayFront + constants.config.sendMailContact;
    return this.http.post(url, body, { headers });
  }

  contentCms(ubication: string) {
    const urlCms = this.env.apiGatewayFront + constants.config.getContentCms + `=${ubication}`;
    this.contenidoCms = this.http.get(urlCms);
    return this.contenidoCms;
  }

  contactos_para() {
    const url = this.env.apiGatewayFront + constants.config.getContactosPara;

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const body: FormData = new FormData();

    body.append('company', JSON.stringify(environment['company']));

    this.contactos_par = this.http.post<any>(url, body, { headers });

    return this.contactos_par;

    this.contactos_par = this.http.get(url);

    return this.contactos_par;
  }
}
