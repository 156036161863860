import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { EnvService } from '@app/common/services/env.service';
import { constants } from '@app/common/constants/app.constants';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OfflineService {

  constructor(private http: HttpClient, private env: EnvService) {
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  getStatusPage(): Observable<any> {

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const urlValidateRteIvaGeneration = this.env.apiGatewayFront + constants.config.pageOffline;
    const body: FormData = new FormData();

    body.append('company', JSON.stringify(environment['company']));

    return this.http.post<any>(urlValidateRteIvaGeneration, body, { headers });

    // return this.http.get<any>(url)
    //   .pipe(
    //     retry(1)
    //   );
  }
}
