<div *ngIf="typeContent == 'espacios'">
  <div *ngIf="contentData[typeContent][positions]">
    <div [innerHTML]="contentData[typeContent][positions].html | domSeguro"></div>
  </div>
</div>

<div *ngIf="typeContent == 'imagen'">

  <div *ngIf="contentData?.contenido?.source == 'imagen'">
    <a [href]="contentData.contenido.url_redirect" target="{{ contentData.contenido.target }}">
      <img [src]="contentData.contenido.imagen" [alt]="contentData.contenido.text_imagen"
        style="height: 400px; width: 100%;">
    </a>
  </div>

  <div *ngIf="contentData?.contenido?.source == 'urlImagen'">
    <a [href]="contentData.contenido.url_redirect" target="{{ contentData.contenido.target }}">
      <img [src]="contentData.contenido.url" [alt]="contentData.contenido.text_imagen"
        style="height: 400px;  width: 100%;">
    </a>
  </div>
</div>


<div *ngIf="typeContent == 'html'" [innerHTML]="contentData?.contenido?.html_code">
</div>


<div *ngIf="typeContent == 'carrusel'">

  <div id="carouselInfo" class="carousel slide" data-ride="carousel" [attr.data-interval]="secondsTransicion">
    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let item of contentData?.contenido?.contenido_carrusel; let i = index"
        [ngClass]="{'active': i === 0}">
        <ng-container *ngIf="item.type_content === 'imagen'">

          <div *ngIf="item.source == 'urlImagen' ">
            <a [href]="item.url_redirect" target="{{ item.target }}" class="d-block w-100">
              <img class="d-block w-100" [src]="item.url" [alt]="item.text_carrusel"
                style="height: 400px; width: auto;">
            </a>
          </div>

          <div *ngIf="item.source == 'imagen' ">
            <a [href]="item.url_redirect" target="{{ item.target }}" class="d-block w-100">
              <img class="d-block w-100" [src]="item.imagen" [alt]="item.text_carrusel"
                style="height: 400px; width: auto;">
            </a>
          </div>
        </ng-container>

        <ng-container *ngIf="item.type_content === 'html'">
          <div [innerHTML]="item.html_code"></div>
        </ng-container>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselInfo" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Anterior</span>
    </a>
    <a class="carousel-control-next" href="#carouselInfo" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Siguiente</span>
    </a>
  </div>
</div>