import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '@app/common/services/env.service';
import { constants } from '@app/common/constants/app.constants';
import { SearchCompanyModel } from "@app/common/types/SearchCompanyModel";
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SearchCompanyService {

  private url: string;
  private contentB2s: any = {};
  private contentCms: any;
  private ubicacion = 'SOLICITUD_REGISTRO_PROVEEDORES';
  private ubicacionError = 'TERCERO_NO_EXISTE';

  constructor(private http: HttpClient, private env: EnvService) {
    this.url = this.env.apiGatewayFront;
  }

  contentParamsB2s() {
    const urlParams = this.url + constants.config.getContentAGP; //TODO: Revisar
    this.contentB2s = this.http.get(urlParams);
    return this.contentB2s;
  }

  contentCmsProvider() {
    const urlParams = `${this.url}${constants.config.getContentCms}=${this.ubicacion}`;
    this.contentCms = this.http.get(urlParams);
    return this.contentCms;
  }

  getDataLogin(data: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const body: FormData = new FormData();
    const url = this.env.apiGatewayFront + constants.config.getContent_loginAGP;
    body.append('formData', JSON.stringify(data));
    body.append('company', JSON.stringify(environment['company']));

    return this.http.post<any>(url, body, { headers });
  }


  postSendDataCompany(nitCompany) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const body: FormData = new FormData();

    body.append('nit', nitCompany);

    return this.http.post<SearchCompanyModel>(this.url + constants.config.getCompanySuplier, body, { headers });
  }

  postGetDataCompany(nitCompany) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const body: FormData = new FormData();

    body.append('nit', nitCompany);

    return this.http.post(this.url + constants.config.getCompanySuplierRegister, body, { headers });
  }

  contenCmsError() {
    const urlParams = `${this.url}${constants.config.getContentCms}=${this.ubicacionError}`;
    this.contentCms = this.http.get(urlParams);
    return this.contentCms;
  }

}
