import { Component, Input, OnInit, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-advertisements',
  templateUrl: './advertisements.component.html',
  styleUrls: ['./advertisements.component.scss']
})
export class AdvertisementsComponent implements OnInit, AfterViewInit {
  @Input() public positions: number;
  @Input() public typeContent: string;
  @Input() public contentData: any;
  @ViewChild('carouselInfo') carousel: ElementRef;
  public secondsTransicion: number;




  constructor() { }

  ngOnInit() {
    if (this.contentData) {
      this.typeContent = this.contentData.contenido.type_content;
    }

    if (this.typeContent == 'carrusel') {
      if (this.contentData.contenido.seconds_transition > 0) {
        this.secondsTransicion = this.contentData.contenido.seconds_transition * 1000;
      } else {
        this.secondsTransicion = 30000;
      }

    }

  }

  ngAfterViewInit() {
    // Inicializa el carrusel aquí
    $('#carouselInfo').carousel();
  }

}
