import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpBackend } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { constants } from '../constants/app.constants';
import { TokenService } from '../services/token.service';
import { catchError, tap } from 'rxjs/operators';
import { prepareSyntheticPropertyName } from "@angular/compiler/src/render3/util";
import { Router } from "@angular/router";
import { EnvService } from '@app/common/services/env.service';
import { environment } from '../../../environments/environment';

const API_URL = constants.config.apiUrl;

const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  }),
  withCredentials: true,
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private redirectUrl = '';
  private backHttp: HttpClient;

  private static handleError(error: any): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.message}, ` +
        `body was: ${error.errors.toString()}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

  private static log(message: string): any {
    console.log(message);
  }

  constructor(private http: HttpClient,
    private tokenService: TokenService,
    private _router: Router,
    private env: EnvService,
    handler: HttpBackend) {
    this.backHttp = new HttpClient(handler);
  }

  public login(loginData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    const body = new HttpParams()
      .set('username', loginData.username)
      .set('password', loginData.password)
      .set('company', loginData.company)
      .set('applicationType', environment['applicationType'] ? environment['applicationType'] : '')

    return this.http.post<any>(API_URL + constants.config.userLogin, body, HTTP_OPTIONS)
      .pipe(
        tap((res: any) => {
          this.setAuthData(res.access_token, res.refresh_token, res.expires_in);
          this.tokenService.saveClientId(res.client_id);
          this.tokenService.saveClientSecret(res.client_secret);
        }),
        catchError(AuthService.handleError)
      );
  }

  public refreshToken(): Observable<any> {
    const body = new HttpParams()
      .set('client_id', this.tokenService.getClientId())
      .set('client_secret', this.tokenService.getClientSecret())
      .set('refresh_token', this.tokenService.getRefreshToken())
      .set('grant_type', 'refresh_token');
    return this.backHttp.post<any>(API_URL + 'webservices/auth/token/', body, HTTP_OPTIONS)
      .pipe(
        tap((res: any) => {
          this.setAuthData(res.access_token, res.refresh_token, res.expires_in);
        }),
        catchError(AuthService.handleError)
      );
  }

  public logout(): void {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
  }

  public secured(): Observable<any> {
    return this.http.get<any>(API_URL + 'secret')
      .pipe(catchError(AuthService.handleError));
  }

  public userParamInfo(username: string, password: string) {

    const url = constants.config.apiUrl + constants.config.userLogin;

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const body: FormData = new FormData();

    body.append('username', username);
    body.append('password', password);

    return this.http.post(url, body, { headers });
  }

  public isAutenticate() {
    const localData = localStorage.getItem('session_token');
    try {
      if (localData) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  private setAuthData(access_token: string, refresh_token: string, expires_in: string) {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    this.tokenService.saveToken(access_token);
    this.tokenService.saveRefreshToken(refresh_token);
    this.tokenService.saveExpiresToken(expires_in);
  }

  logoutService() {
    localStorage.removeItem('access_token');
    const url = constants.config.apiUrl + constants.config.userLogout;
    this.http.get(url).subscribe(response => {
      localStorage.removeItem('authenticate');
      localStorage.removeItem('session-token');
      localStorage.removeItem('session_token');
      localStorage.removeItem('userData');
      this._router.navigate(['/login']);
    });
  }

  getDataLogin(data: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const body: FormData = new FormData();
    const url = this.env.apiGatewayFront + constants.config.getContent_loginAGP;

    body.append('formData', JSON.stringify(data));
    body.append('company', JSON.stringify(environment['company']));

    return this.http.post<any>(url, body, { headers });
  }

}
