import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { EnvService } from '@app/common/services/env.service';
import { constants } from '@app/common/constants/app.constants';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private contentHeader: any = {};

  constructor(private http: HttpClient,
    private env: EnvService) {
  }

  getAGPParams(company) {
    const urlHeader = constants.config.apiUrl + constants.config.getContentAGP + "?app_type=" + constants.config.app_type;
    this.contentHeader = this.http.get(urlHeader, {
      withCredentials: true,
      headers: {
        company: company
      }
    });
    return this.contentHeader;
  }

  getInitData(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');

    const urlHeader = this.env.apiGatewayFront + constants.config.getInitData;
    const body: FormData = new FormData();

    body.append('company', JSON.stringify(environment['company']));

    return this.http.post<any>(urlHeader, body, { headers });
    // this.contentHeader = this.http.get(urlHeader);
    // return this.contentHeader;
  }

  getMenuData(): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const body = {
      appName: "agp"
    };
    const url = constants.config.apiUrl + constants.config.getAppContent;
    return this.http.post<any>(url, body, { headers });
  }

}
